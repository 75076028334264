import { INote } from "shared";
import { useState } from "react";
import { When } from "react-if";
import { TextareaAutosize, Tooltip } from "@mui/material";

import { classNames } from "../../../common/lib/classNames";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { useAccountingSystemName } from "../../hooks/useAccountingSystemName";

import { Note } from "./Note";

interface NotesProps {
  notes: INote[];
  enableNewNote?: boolean;
  onSave: (content: string) => Promise<void>;
  loading?: boolean;
}

export function Notes({ notes, enableNewNote, onSave, loading }: NotesProps) {
  const accountingName = useAccountingSystemName();
  const [newNoteForm, setNewNoteForm] = useState({
    content: ``,
    error: ``,
  });

  function onChange(str: string) {
    if (str.length > 1500) {
      setNewNoteForm({ content: str, error: `Note must be less than 1500 characters` });

      return;
    }

    setNewNoteForm({ error: ``, content: str });
  }

  async function onNewNote(e: React.FormEvent) {
    e.preventDefault();

    await onSave(newNoteForm.content);

    // Clear the form
    setNewNoteForm({ content: ``, error: `` });
  }

  return (
    <div>
      { /* Create a new note */ }
      <When condition={ enableNewNote }>
        <form
          className={ `border border-gray-200 rounded-md mb-3` }
          onSubmit={ onNewNote }
        >
          <div className={ `flex flex-col items-end` }>
            <TextareaAutosize
              value={ newNoteForm.content }
              onChange={ e => onChange(e.target.value.toString()) }
              placeholder={ `Write a new note...` }
              className={ classNames(
                `border-none w-full text-sm focus:border-none`,
                newNoteForm.error ? `border border-red-500` : ``,
              ) }
              style={ { resize: `none` } }
            />

            <Tooltip title={ `Notes are also automatically sent to ${accountingName}.` }>
              <span>
                <button
                  type={ `submit` }
                  className={ `border border-gray-200 rounded-md p-2 m-2 text-sm hover:bg-lateGreen-500 hover:text-white disabled:cursor-not-allowed disabled:hover:bg-gray-200 disabled:hover:text-gray-600 disabled:bg-gray-200 disabled:opacity-50` }
                  disabled={ loading  || !newNoteForm.content || !!newNoteForm.error }
                >
                  <When condition={ loading }>
                    { `Adding Note...` }
                  </When>
                  <When condition={ !loading }>
                    { `Add Note` }
                  </When>
                </button>
              </span>
            </Tooltip>
          </div>
          <When condition={ newNoteForm.error }>
            <Paragraph variant={ `error` }>
              { newNoteForm.error }
            </Paragraph>
          </When>
        </form>
      </When>

      { /* Display existing notes */ }
      <div className={ `w-full space-y-3` }>
        {
          notes.map(note => (
            <Note
              key={ note.id }
              note={ note }
            />
          ))
        }
      </div>
    </div>
  );
}
