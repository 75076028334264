import { IInvoiceListResponse, IInvoiceParams, IInvoiceResponse } from 'shared';

import { CacheTags } from '../../types/cacheTags';
import { orgHeader } from '../axiosBaseQuery';
import { emptySplitApi } from '../baseApi';

export const invoiceApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchInvoices: builder.query<IInvoiceListResponse, IInvoiceParams & { organisationId: string }>({
      query: ({
        organisationId,
        page,
        sortColumn,
        sortType,
        invoiceTextSearch,
        dueDate,
        xeroStatus,
        limit,
      }) => ({
        url: `invoice`,
        method: `GET`,
        params: {
          page,
          sortColumn,
          sortType,
          invoiceTextSearch,
          dueDate,
          xeroStatus,
          limit,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => result.invoices ?
        [
          ...result.invoices.map(({ invoice }) => ({ type: CacheTags.Invoices, id: invoice.id })),
          { type: CacheTags.Invoices, id: `PARTIAL-LIST` },
        ]
        : [{ type: CacheTags.Invoices, id: `PARTIAL-LIST` }],
    }),
    fetchInvoice: builder.query<IInvoiceResponse, { organisationId: string, invoiceId: string}>({
      query: ({
        organisationId,
        invoiceId,
      }) => ({
        url: `invoice/${invoiceId}`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      providesTags: result => result &&
        [
          { type: CacheTags.Invoices, id: result.invoice?.id },
        ],
    }),
    actionInvoice: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        action,
        penaltyId,
        discountId,
        actionId,
      }) => ({
        url: `invoice/${invoiceId}/action`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          action,
          penaltyId,
          discountId,
          actionId,
        },
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      transformResponse: (response: any) => response?.invoice,
      invalidatesTags: ({ id }) => {
        return [{ type: CacheTags.Invoices, id }, { type: CacheTags.Actions, id: `LIST` }];
      },
    }),
    actionInvoices: builder.mutation({
      query: ({
        organisationId,
        invoiceIds,
        action,
      }) => ({
        url: `invoice/action/${action}`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          invoiceIds,
        },
      }),
      invalidatesTags: ({ invoiceIds, action }) => {
        if (action === `sync-all`) {
          return [{ type: CacheTags.Organisations, id: `LIST` }];
        }

        return invoiceIds?.map(id => ({ type: CacheTags.Invoices, id }));
      },
    }),
    sendInvoiceReminder: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        templateId,
      }) => ({
        url: `invoice/${invoiceId}/send-reminder`,
        method: `GET`,
        params: {
          templateId,
        },
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    aiCallNow: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        phoneNumber,
      }) => ({
        url: `invoice/${invoiceId}/ai-call-now`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          phoneNumber,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    applyPolicyPreview: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        policyId,
      }) => ({
        url: `invoice/${invoiceId}/preview-policy/${policyId}/apply`,
        method: `GET`,
        headers: {
          [orgHeader]: organisationId,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    setExpectedPaymentDate: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        date,
      }) => ({
        url: `invoice/${invoiceId}/expected-payment-date`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          date,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }, { type: CacheTags.Actions, id: `LIST` }],
    }),
    createNote: builder.mutation({
      query: ({
        organisationId,
        invoiceId,
        content,
      }) => ({
        url: `invoice/${invoiceId}/note`,
        method: `POST`,
        headers: {
          [orgHeader]: organisationId,
        },
        data: {
          content,
        },
      }),
      invalidatesTags: ({ invoiceId }) => [{ type: CacheTags.Invoices, id: invoiceId }],
    }),
  }),
});

export const {
  useFetchInvoiceQuery,
  useFetchInvoicesQuery,
  useActionInvoiceMutation,
  useActionInvoicesMutation,
  useSendInvoiceReminderMutation,
  useSetExpectedPaymentDateMutation,
  useApplyPolicyPreviewMutation,
  useAiCallNowMutation,
  useCreateNoteMutation,
} = invoiceApi;
