import { When } from 'react-if';
import { IActionResponseItem } from 'shared';
import { useNavigate } from 'react-router';

import { classNames }  from '../../../common/lib/classNames';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';
import { useActionTableModels } from '../../hooks/useActions';
import { useGetSelectedOrganisation } from '../../hooks/useGetSelectedOrganisation';

import { TimelineBasicItem } from './TimelineBasicItem';

interface ITimelineProps {
  actions: IActionResponseItem[];
  emptyMessage?: string | React.ReactNode;
  context: `invoice` | `contact`;
  viewMoreSearchStr: string;
}

export function Timeline({ actions, emptyMessage, context, viewMoreSearchStr }: ITimelineProps) {
  const navigate = useNavigate();
  const currentOrg = useGetSelectedOrganisation();
  const { setFilterModel, setCurrentTab } = useActionTableModels();

  actions = actions || [];

  function goToActions(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();

    if (context === `invoice`) {
      setFilterModel({
        items: [
          {
            field: `invoiceNumber`,
            operator: `contains`,
            value: viewMoreSearchStr,
          },
        ],
      });
    }

    // Make sure we on the all tab
    setCurrentTab(null);

    navigate(`/${currentOrg.id}/actions?persist=true`);
  }

  function renderViewAllMessage() {
    if (context === `invoice`) {
      return `View All Actions For Invoice`;
    }

    if (context === `contact`) {
      return `View All Actions For Contact`;
    }
  }

  return (
    <div className={ `space-y-6 mt-6` }>
      <ul role={ `list` }
        className={
          classNames(
            `space-y-6`,
          )
        }>
        <When condition={ actions.length === 0 && !!emptyMessage }>
          <li className={ `flex items-center justify-center` }>
            <p className={ `text-sm text-gray-500` }>
              { emptyMessage }
            </p>
          </li>
        </When>
        { actions.map((action, index) => (
          <li key={ action.id }
            className={ `relative flex gap-x-4` }>
            <div
              className={ classNames(
                index === actions.length - 1 ? `h-6` : `-bottom-6`,
                `absolute left-0 top-0 flex w-6 justify-center`,
              ) }
            >
              <div className={ `w-px bg-gray-200` } />
            </div>

            <TimelineBasicItem action={ action } />
          </li>
        )) }
      </ul>
      <Paragraph
        className={ `mb-4` }
      >
        <a
          href={ `#` }
          onClick={ goToActions }
          target={ `_blank` }
          rel={ `noopener noreferrer` }
        >
          { renderViewAllMessage() }<span aria-hidden={ `true` }>&rarr;</span>
        </a>
      </Paragraph>
    </div>
  );
}
