import { useMemo, useState } from 'react';
import { DateFormats, IPlanPriceV3, IPlanV3, StripePricesLookupKeys, SubscriptionStatus, allowedSubscriptionStatus, findPlanByLookupKey, findPriceByLookupKey } from 'shared';
import { useLocation, useNavigate, useParams } from 'react-router';
import { When } from 'react-if';
import { format } from 'date-fns';

import { Badge } from '../../../common/Atoms/Badge';
import { Button } from '../../../common/Atoms/Button';
import { Card } from '../../../common/Atoms/Card';
import { CardContent } from '../../../common/Atoms/CardContent';
import { DescriptionList } from '../../../common/Atoms/DescriptionList';
import { Divider } from '../../../common/Atoms/Divider';
import { Grid } from '../../../common/Atoms/Grid';
import { GridItem } from '../../../common/Atoms/GridItem';
import { Select } from '../../../common/Atoms/Select';
import { Notification } from '../../../common/Atoms/Notification';
import { env } from '../../../common/lib/env';
import { events, logAnalyticEvent } from '../../lib/analytics';
import { useUser } from '../../hooks/useUser';
import { useSubscriptions } from '../../hooks/useSubscriptions';
import { useOrganisations } from '../../hooks/useOrganisations';
import { useActionSubscriptionMutation, useAddOnOrgMutation, useDeleteSubscriptionMutation } from '../../services/api/subscriptionApi/subscription';
import { useLoading } from '../../hooks/useLoading';
import { MultiButtonCardHeader } from '../../../common/Atoms/MultiButtonCardHeader';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { Heading } from '../../../common/Atoms/Typography/Heading';
import { Paragraph } from '../../../common/Atoms/Typography/Paragraph';

import { NewPlans } from './NewPlans';
import { SmsLimit } from './SMSLimit';

export function Subscription() {
  const id = Number(useParams().subscriptionId);
  const { data: subscriptions } = useSubscriptions();
  const actionSubscriptionMutation = useActionSubscriptionMutation();
  const navigate = useNavigate();
  const [deleteSubscription, {
    isLoading: deleteLoading,
  }] = useDeleteSubscriptionMutation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [actionSubscription, actionLoadings] = useLoading(actionSubscriptionMutation, `organisationId`) as any;
  const [purchaseOrgAddOn, { isLoading: purchaseOrgAddOnLoading }] = useAddOnOrgMutation();
  const location = useLocation();
  const { data: user } = useUser();
  const { data: organisations } = useOrganisations();
  const [ error ] = useState(``);
  const [ selectedOrgToLink, setSelectedOrgToLink ] = useState(null);
  const featureFlags = useFeatureFlags();

  const subscription = useMemo(() => {
    return subscriptions.find(s => s.id === id);
  }, [ subscriptions]);

  const isLegacy = subscription?.pricingVersion === 2;

  const success = useMemo(() => {

    const params = new URLSearchParams(location.search);
    const success = params.get(`success`);

    if (success) {
      logAnalyticEvent(events.checkoutSuccess, {
        userId: user.id,
      });
    }

    return success;

  }, [location]);

  const planDetails: {
    maxAccountingConnections: number;
    name: string;
    periodStart: string;
    periodEnd: string;
    priceConfig: IPlanPriceV3;
    planConfig: IPlanV3;
  } = useMemo(() => {
    if (!subscription || !subscription.stripeSubscriptionId) return null;

    const {
      periodEnd,
      periodStart,
    } = subscription;
    const priceConfig = findPriceByLookupKey(subscription.mainLookupKey as StripePricesLookupKeys);
    const planConfig = findPlanByLookupKey(subscription.mainLookupKey as StripePricesLookupKeys);

    return {
      maxAccountingConnections: subscription.usage.organisations.max,
      name: priceConfig.name,
      periodStart: periodStart ? format(new Date(Number(periodStart) * 1000), DateFormats.UI_DISPLAY_LONG) : `N/A`,
      periodEnd: periodEnd ? format(new Date(Number(periodEnd) * 1000), DateFormats.UI_DISPLAY_LONG) : `N/A`,
      priceConfig,
      planConfig,
    };
  }, [ subscription ]);

  const tagColor = useMemo(() => {
    if (subscription?.status) {
      const status = subscription.status;
      let color = status === SubscriptionStatus.ACTIVE ? `green` : `red`;

      if (status === SubscriptionStatus.TRIALING) {
        color = `orange`;
      }

      return color;
    }
  }, [ subscription?.status ]);

  const stats = useMemo(() => {
    if (subscription?.status) {
      const items: {
        title: string;
        content: string | number | JSX.Element;
        subText?: string;
      }[] = [
        {
          title: `Current Price`,
          content: subscription.priceReadable,
        },
        {
          title: `Invoices Used`,
          content: `${subscription.usage.invoices.current} / ${subscription.usage.invoices.max}`,
        },
        {
          title: `Emails Used`,
          content: `${subscription.usage.emails.current} / ${subscription.usage.emails.max}`,
        },
        {
          title: `Actions Used`,
          content: `${subscription.usage.actions.current}`,
        },
        {
          title: `Contacts Actioned`,
          content: `${subscription.usage.contacts.current}`,
        },
        {
          title: `SMS Monthly Limit`,
          content: <SmsLimit
            subscription={ subscription }
          />,
        },
        {
          title: `Subscription Status`,
          content: <Badge
            color={ tagColor }
            message={ subscription.status }
            size={ `md` }
          />,
        },
      ];

      if (isLegacy) {
        items.push({
          title: `Maximum Organisations`,
          content: (planDetails?.maxAccountingConnections || 1) > 1 ?
            `${planDetails?.maxAccountingConnections} Organisations` : `1 Organisation`,
        });
      }
      else {
        items.push({
          title: `Maximum Organisations`,
          content: (planDetails?.maxAccountingConnections || 1) > 1 ?
            `${planDetails?.maxAccountingConnections} Organisations (+ $${(planDetails.maxAccountingConnections - 1) * 10}) (To reduce this limit, contact us)` : `1 Organisation`,
        });
      }

      if (planDetails) {
        items.push({
          title: `Billing Period`,
          content: `${planDetails?.periodStart} - ${planDetails?.periodEnd}`,
        });

        items.unshift({
          title: `Current Plan Name`,
          content: isLegacy ? `${planDetails?.name} (Previously ${planDetails?.priceConfig?.previouslyNamed})` : `${planDetails?.name}`,
        });
      }

      return items;
    }

    return [];
  }, [ planDetails, subscription ]);

  const orphanedOrgsOptions = useMemo(() => {
    const currentOrgs = subscription?.organisations?.map(o => o.id);

    return organisations
      .filter(o => !currentOrgs?.includes(o.id))
      .map(o => {

        if (o.subscriptionMetadata?.status === SubscriptionStatus.ACTIVE || o.subscriptionMetadata?.status === SubscriptionStatus.TRIALING) return {
          label: `${o.legalName} (already linked by ${o.billingManagedBy})`,
          value: o.id,
          disabled: true,
        };

        return {
          label: o.legalName,
          value: o.id,
        };
      });
  }, [ organisations, subscription ]);

  function onManage() {
    (window as Window).location = `${env.apiUrl}/api/v1/payments/create-portal-session?subscriptionId=${id}`;
  }

  function unlinkOrganisation(id) {
    actionSubscription({
      id: subscription.id,
      action: `unlink`,
      organisationId: id,
    });
  }

  function linkOrganisation() {
    actionSubscription({
      id: subscription.id,
      action: `link`,
      organisationId: selectedOrgToLink,
    });
  }

  async function deleteSub() {
    await deleteSubscription({
      id: subscription.id,
    });

    navigate(`/billing/subscriptions`);
  }

  function onPurchaseAddOn() {
    purchaseOrgAddOn({
      subscriptionId: subscription.id,
    });
  }

  function onWalletTopUp() {
    (window as Window).location = `${env.apiUrl}/api/v1/payments/wallet/top-up?subscriptionId=${id}`;
  }

  if (!subscription) return null;

  const showPlans = [
    SubscriptionStatus.INACTIVE,
    SubscriptionStatus.CANCELED,
    SubscriptionStatus.NONE,
  ].includes(subscription.status);

  function renderLinkOrgButton() {
    if (planDetails?.planConfig?.name !== `Pro`) {
      return (
        <Button
          disabled={ subscription?.organisations?.length >= subscription?.usage?.organisations?.max || !selectedOrgToLink }
          onClick={ linkOrganisation }
        >
          {
            subscription?.organisations?.length >= subscription?.usage?.organisations.max ? `Organisation Limit Reached` : `Link To Subscription`
          }
        </Button>
      );
    }

    if (subscription?.organisations?.length >= subscription?.usage?.organisations?.max) {
      return (
        <Button
          disabled={ purchaseOrgAddOnLoading }
          onClick={ onPurchaseAddOn }
        >
          {
            `Purchase Company Share Add On ($10 / Month)`
          }
        </Button>
      );
    }

    return (
      <Button
        disabled={ subscription?.organisations?.length >= subscription?.usage?.organisations?.max || !selectedOrgToLink }
        onClick={ linkOrganisation }
      >
        {
          `Link To Subscription`
        }
      </Button>
    );
  }

  return (
    <div>
      <Card id={ `subscription-item-${subscription.id}` }>
        <When condition={ success }>
          <Notification type={ `success` }>
            { `Thank you for choosing a plan - your subscription is now active. ` }<br></br>{ `You can manage your subscription below or ` }<a className={ `underline` }
              href={ `/` } >{ `go to your account dashboard` }</a>{ `.` }
          </Notification>
        </When>
        <When condition={ error }>
          <Notification type={ `error` }>
            { error }
          </Notification>
        </When>
        <When condition={ !showPlans }>
          <MultiButtonCardHeader
            title={ `Manage Subscription` }
            buttons={ [
              {
                buttonText: `Update Payment Method & View Invoices`,
                onClick: onManage,
              },
            ] }
          />
        </When>
        <CardContent>
          <div className={ `flex flex-col space-y-6 w-full` }>
            <NewPlans />
            <div className={ `py-4` }></div>
          </div>

          <Divider />
          <When condition={ subscription.organisations?.length }>
            <DescriptionList
              title={ `Linked Organisations` }
              description={ `Organisations which are linked to this subscription` }
              items={
                subscription.organisations?.map(org => ({
                  title: org.legalName,
                  child: <Button
                    data-cy={ `unlink-organisation-${org.id}` }
                    loading={ actionLoadings[org.id] }
                    onClick={ () => unlinkOrganisation(org.id) }>{ `Unlink` }</Button>,
                })) || []
              }
            />
          </When>
          <When condition={ allowedSubscriptionStatus.includes(subscription.status) }>
            <Grid cols={ 3 }
              className={ `mt-6` }>
              <GridItem span={ 2 }>
                <Select
                  label={ `Link Organisation` }
                  nullable
                  options={ orphanedOrgsOptions }
                  selected={ selectedOrgToLink }
                  onChange={ e => setSelectedOrgToLink(e.value) }
                  name={ `organisation select` }
                />
              </GridItem>

              <GridItem span={ 1 }
                className={ `flex items-end justify-end` }>
                { renderLinkOrgButton() }
              </GridItem>
            </Grid>
            <Divider />

          </When>
          <When condition={ subscription.status }>
            <DescriptionList
              items={ stats }
              title={ `Current Plan & Usage` }
              className={ `mt-6` }
            />
          </When>

          <Divider />
        </CardContent>

        <MultiButtonCardHeader
          title={ `` }
          description={ `Add/update credit cards, change your billing address and view PDF invoices for your subscription.` }
          noDivider
        />
        <CardContent>
          <div className={ `flex flex-row justify-between items-end` }>
            <Grid cols={ 3 }>
              <GridItem span={ 1 }>
                <Button onClick={ onManage }>
                  { `Manage payment methods` }
                </Button>
              </GridItem>
              <GridItem span={ 1 }>
                <Button onClick={ onManage }>
                  { `Manage billing addresses` }
                </Button>
              </GridItem>
              <GridItem span={ 1 }>
                <Button onClick={ onManage }>
                  { `View subscription invoices` }
                </Button>
              </GridItem>
            </Grid>
            <When condition={ !subscription?.status }>
              <Button
                color={ `red` }
                onClick={ deleteSub }
                loading={ deleteLoading }
              >
                { `Delete Subscription` }
              </Button>
            </When>
          </div>
        </CardContent>
      </Card>

      { /* Wallet info */ }
      <When condition={ featureFlags?.ai }>
        <Card className={ `mb-4` }>
          <MultiButtonCardHeader
            title={ `Calling Credit Balance ` }
            description={ `Your balance can be used to pay for AI call minutes.` }
            buttons={ [
              {
                buttonText: `Purchase Credits`,
                onClick: onWalletTopUp,
              },
            ] }
          />
          <CardContent>
            <div className={ `grid grid-cols-4 mt-4` }>
              <Heading className={ `col-span-3` }>
                { `Current Balance:` }
              </Heading>
              <div className={ `col-span-1 text-right` }>
                <Paragraph
                  size={ `md` }
                >
                  { subscription?.walletBalanceFormatted } { ` Credits` }
                </Paragraph>
                <Paragraph variant={ `help` }>
                  { `$1.00 USD = 1 Credit` }
                </Paragraph>
              </div>
            </div>
          </CardContent>
        </Card>
      </When>
    </div>
  );
}
