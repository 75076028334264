import { EmailTemplateTypes, PolicyIntervalType, PolicyStatementType, StatementRelativePeriods, getOrdinalSuffix, policyWeeklyDayIntervalMap } from 'shared';
import { useMemo } from 'react';

import { InputTextAddon } from '../../../../../common/Atoms/InputTextAddon';
import { fieldIsValid } from '../../../../lib/helper';
import { Grid } from '../../../../../common/Atoms/Grid';
import { GridItem } from '../../../../../common/Atoms/GridItem';
import { Select } from '../../../../../common/Atoms/Select';
import { IPolicyProps } from '../PolicyItem';
import { Paragraph } from '../../../../../common/Atoms/Typography/Paragraph';
import { Transition } from '../../../../../common/Atoms/Transition';
import { EmailTemplateSelector } from '../PolicyForm/Fields/EmailTemplateSelector';

const intervalOptions = [
  {
    label: `Monthly`,
    value: PolicyIntervalType.MONTHLY,
  },
  {
    label: `Weekly`,
    value: PolicyIntervalType.WEEKLY,
  },
];

const typeOptions = [
  {
    label: `Outstanding - Send only to contacts with an outstanding balance`,
    value: PolicyStatementType.OUTSTANDING,
  },
  {
    label: `Overdue - Send only to contacts with an overdue balance`,
    value: PolicyStatementType.OVERDUE,
  },
  {
    label: `All - Send to all customers even those with no balance`,
    value: PolicyStatementType.ALL,
  },
];

const periodOptions = [
  {
    label: `All Outstanding Items`,
    value: StatementRelativePeriods.ALL_TIME,
  },
  {
    label: `View all outstanding & future`,
    value: StatementRelativePeriods.ALL_TIME_INC_FUTURE,
  },
  {
    label: `Issued before current month`,
    value: StatementRelativePeriods.UPTO_PREVIOUS_MONTH,
  },
  {
    label: `Issued only in the previous month`,
    value: StatementRelativePeriods.PREVIOUS_MONTH,
  },
  {
    label: `Issued only in the current month`,
    value: StatementRelativePeriods.THIS_MONTH,
  },
];

export function PolicyStatement({
  policy,
  invalids,
  handleChange,
}: IPolicyProps) {
  const intervalValues = useMemo(() => {
    if (!policy) return [];

    if (policy.interval_type === PolicyIntervalType.MONTHLY) {
      return Array.from(Array(31).keys()).map(i => ({
        label: `${i + 1}${getOrdinalSuffix(i + 1)}`,
        value: i + 1,
      }));
    }

    return Object.keys(policyWeeklyDayIntervalMap).map(key => ({
      label: policyWeeklyDayIntervalMap[key],
      value: Number(key),
    }));

  }, [policy?.interval_type]);

  return (
    <>
      <Paragraph
        variant={ `secondary` }
      >
        { `Statements will be for outstanding amounts and will be sent on the day you select below.` }
      </Paragraph>
      <Grid cols={ 3 }>
        <GridItem span={ 1 }
          position={ `bottom` }
        >
          <Select
            tourTarget={ `statementInterval` }
            label={ `When should this statement be sent?` }
            options={ intervalOptions }
            onChange={ handleChange }
            selected={ policy.interval_type }
            name={ `interval_type` }
          />
        </GridItem>
        <GridItem span={ 1 }
          position={ `bottom` }>
          <Select
            label={ `Which day of the month?` }
            options={ intervalValues }
            onChange={ handleChange }
            selected={ policy.day_index }
            name={ `day_index` }
            helpIcon={ `If the day of the month is greater than the number of days in the month, the 1st of the next month will be used` }
          />
        </GridItem>

        <GridItem span={ 1 }
          position={ `bottom` }>
          <Select
            label={ `Statement Period` }
            options={ periodOptions }
            onChange={ handleChange }
            selected={ policy.statement_period }
            name={ `statement_period` }
            helpIcon={
              `Which Invoices & Credits to include in the statement. \n Current and previous months are relative to the day in which the statement is sent. Based on Issue Date.`
            }
          />
        </GridItem>
      </Grid>

      <Grid cols={ 12 }>
        <GridItem span={ policy.statement_type === PolicyStatementType.OVERDUE ? 8 : 12 }>
          <Select
            tourTarget={ `statementTrigger` }
            label={ `Statement Trigger Type` }
            options={ typeOptions }
            onChange={ handleChange }
            selected={ policy.statement_type }
            name={ `statement_type` }
          />
        </GridItem>
        <GridItem span={ 4 }>

          <Transition
            show={ policy.statement_type === PolicyStatementType.OVERDUE }
            speed={ `slow` }
          >
            <div>
              <InputTextAddon
                dataType={ `number` }
                value={ policy.overdue_age_min  || 0 }
                onChange={ handleChange }
                name={ `overdue_age_min` }
                label={ `Minimum Overdue Age` }
                description={ `Only send statement if at least one invoice is overdue by more than this` }
                invalid={ fieldIsValid(`overdue_age_min`, invalids) }
              />
            </div>
          </Transition>
        </GridItem>
      </Grid>

      <Grid cols={ 1 }>
        <GridItem span={ 1 }>
          <EmailTemplateSelector
            value={ policy.template_id }
            handleChange={ handleChange }
            type={ EmailTemplateTypes.STATEMENT_EMAIL }
            invalid={ fieldIsValid(`template_id`, invalids) }
            label={ `Email template` }
            emptyText={ `Select Template` }
            nullable
            tourTarget={ `statementTemplate` }
            name={ `template_id` }
          />
        </GridItem>
      </Grid>
    </>
  );
}
