import { policyConfig } from "shared";

import { IFieldProps } from "../Fields/fieldProps";
import { ContactTaxRate } from "../Fields/ContactTaxRate";
import { ApplyInvoicesFrom } from "../Fields/ApplyInvoicesFrom";
import { ApplyInvoicesTo } from "../Fields/ApplyInvoicesTo";
import { MinimumDue } from "../Fields/MinimumDue";
import { ExcludeBrandingThemes } from "../Fields/ExcludeBrandingThemes";
import { MaximumDue } from "../Fields/MaximumDue";
import { PenaltyDueDate } from "../Fields/PenaltyDueDate";
import { MinimumFeeAmount } from "../Fields/MinimumFeeAmount";
import { ExcludeAccountLines } from "../Fields/ExcludeAccountLines";
import { StatementChargeFixedAmount } from "../Fields/StatementChargeFixedAmount";
import { InvoiceReference } from "../Fields/InvoiceReference";
import { TimeOfDay } from "../Fields/TimeOfDay";
import { InvoiceNumberPrefix } from "../Fields/InvoiceNumberPrefix";
import { InvoiceReferenceIncludes } from "../Fields/InvoiceReferenceIncludes";
import { OnlyWebhooks } from "../Fields/OnlyWebhooks";
import { DestinationEmailOverride } from "../Fields/DestinationEmailOverride";
import { NewInvoiceBrandingTheme } from "../Fields/NewInvoiceBrandingTheme";
import { ParentOnly } from "../Fields/ParentOnly";
import { SMSDefaultCountryCode } from "../Fields/SMSDefaultCountryCode";
import { SMSFallbackToPhone } from "../Fields/SMSFallbackToPhone";
import { TrackingCategoryClass } from "../Fields/TrackingCategoryClass";
import { ContactTagFilter } from "../Fields/ContactTagFilter";
import { XeroContactsGroupFilter } from "../Fields/XeroContactGroupsFilter";
import { DoNotSendEmailCustomer } from "../Fields/DoNotSendEmailCustomer";
import { FirstTimeOnly } from "../Fields/FirstTimeOnly";

interface IAdvancedFieldProps extends IFieldProps {
  formKey: keyof typeof policyConfig;
}

const componentMap: {
  [key in keyof typeof policyConfig]: React.FC<IFieldProps>;
} = {
  use_contact_tax_rate: ContactTaxRate,
  apply_after_due_date_string: ApplyInvoicesFrom,
  apply_before_due_date_string: ApplyInvoicesTo,
  minimum_threshold: MinimumDue,
  maximum_threshold: MaximumDue,
  exclude_branding_themes: ExcludeBrandingThemes,
  penalty_due_date: PenaltyDueDate,
  min_fee_amount: MinimumFeeAmount,
  exclude_account_lines: ExcludeAccountLines,
  fixed_fee_amount: StatementChargeFixedAmount,
  invoice_reference: InvoiceReference,
  hour_of_day: TimeOfDay,
  use_invoice_number_prefix: InvoiceNumberPrefix,
  reference_contains: InvoiceReferenceIncludes,
  only_webhook: OnlyWebhooks,
  destination_email_override: DestinationEmailOverride,
  new_invoice_branding_theme_id: NewInvoiceBrandingTheme,
  parent_only: ParentOnly,
  sms_default_country_code: SMSDefaultCountryCode,
  sms_fallback_to_phone: SMSFallbackToPhone,
  tracking_category_class: TrackingCategoryClass,
  contact_tags: ContactTagFilter,
  xero_group_name: XeroContactsGroupFilter,
  do_not_send_email_to_customer: DoNotSendEmailCustomer,
  first_time_only: FirstTimeOnly,
};

export function AdvancedField({
  policyFormData,
  invalids,
  handleChange,
  formKey,
}: IAdvancedFieldProps) {
  return componentMap[formKey]({
    policyFormData,
    invalids,
    handleChange,
  });
}
