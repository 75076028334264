// Shows date in system time and organisation timezone
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { useMemo, useState } from 'react';
import { usePopper } from 'react-popper';

import { classNames } from '../lib/classNames';
import { Paragraph } from '../Atoms/Typography/Paragraph';

interface IDatePopoverProps {
  date: Date | string;
  /** required if labelFormatPreset is not defined */
  labelFormat?: string;
  /** required if labelFormat is not defined */
  labelFormatPreset?: DateTimeFormatOptions;
  organisationTimezone: string;
  /** Only display the month for organisation and computer dates */
  monthOnly?: boolean;
  /** If input date is timeless string. @default 'start' */
  time?: `start` | `end`;
  className?: string;
  position?: `top` | `bottom` | `left` | `right` | `auto`;
}

// TODO MAY NEED TO USE REACT PORTAL IF THIS IS USED ON SMALL MODALS ETC

export function DatePopover(
  { date, labelFormat, labelFormatPreset, organisationTimezone, className, monthOnly, time, position = `auto` }: IDatePopoverProps,
) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
  });

  const dateDetail: {
    organisationDate: string;
    computerDate: string;
    label: string;
  } = useMemo(() => {
    // First determine if its a string or a date
    if (typeof date === `string`) {
      if ((/^\d{4}-\d{2}-\d{2}$/).test(date) && !time) {
        // No time, default the start time
        time = `start`;
      }
      let dt = DateTime
        .fromISO(date, { zone: organisationTimezone });

      if (time === `end`) {
        dt = dt.endOf(`day`);
      }

      if (time === `start`) {
        dt = dt.startOf(`day`);
      }

      return returnFormatted(dt);
    }
    else if (date instanceof Date) {
      return returnFormatted(DateTime.fromJSDate(date));
    }
    else {
      console.error(`DatePopover: Invalid date type`, date);

      return {
        organisationDate: `Invalid Date`,
        computerDate: `Invalid Date`,
        label: `Invalid Date`,
      };
    }

    function formatLabel(dt: DateTime) {
      if (labelFormat) {
        return dt
          .setZone(organisationTimezone)
          .toFormat(labelFormat);
      }

      if (labelFormatPreset) {
        return dt
          .setZone(organisationTimezone)
          .toLocaleString(labelFormatPreset);
      }

      console.error(`DatePopover: labelFormat or labelFormatPreset is required`);

      return `Invalid Date`;
    }

    function returnFormatted(dt: DateTime) {
      if (monthOnly) {
        return {
          organisationDate: dt
            .setZone(organisationTimezone)
            .toFormat(`LLLL 'in' ZZZZZ`),
          computerDate: dt
            .setZone() // Leave blank to pick up system timezone
            .toFormat(`LLLL 'in' ZZZZZ`),
          label: formatLabel(dt),
        };
      }

      return {
        organisationDate: dt
          .setZone(organisationTimezone)
          .toLocaleString(DateTime.DATETIME_HUGE),
        computerDate: dt
          .setZone() // Leave blank to pick up system timezone
          .toLocaleString(DateTime.DATETIME_HUGE),
        label: formatLabel(dt),
      };
    }

  }, [date]);

  return (
    <Popover
      className={
        classNames(
          className,
        )
      }
    >
      <PopoverButton
        ref={ setReferenceElement }
        className={ `border-dashed border-gray-500 border-b` }
      >
        { dateDetail.label }
      </PopoverButton>

      <PopoverPanel
        ref={ setPopperElement }
        style={ styles.popper }
        { ...attributes.popper }
        className={
          classNames(
            `z-50`,
            position === `top` && `bottom-full left-0`,
            position === `bottom` && `top-full left-0`,
            position === `left` && `right-full top-0`,
            position === `right` && `left-full top-0`,
          )
        }
      >
        <div className={ `bg-white whitespace-nowrap p-6 rounded-md shadow-md max-w-fit border border-gray-400` }>
          <div className={ `flex justify-between pb-2` }>
            <Paragraph
              className={ `mr-6 text-left` }
            >
              { `Company Date:` }
            </Paragraph>
            <Paragraph
              className={ `text-left` }
              variant={ `help` }
            >
              { dateDetail.organisationDate }
            </Paragraph>
          </div>
          <div className={ `flex justify-between` }>
            <Paragraph
              className={ `mr-6 text-left` }
            >
              { `Your Local Date:` }
            </Paragraph>
            <Paragraph
              variant={ `help` }
              className={ `text-left` }
            >
              { dateDetail.computerDate }
            </Paragraph>
          </div>

        </div>
      </PopoverPanel>
    </Popover>
  );
}
