import { useEffect } from 'react';
import { EditorContent, useEditor, JSONContent, EditorEvents, generateJSON } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Image from '@tiptap/extension-image';
import Dropcursor from '@tiptap/extension-dropcursor';

// import FileHandler from '@tiptap-pro/extension-file-handler';

import { classNames } from '../../../common/lib/classNames';

import { MenuBar } from './TextEditorToolbar';

//  Styles for inside the editor
import '../../css/editor.css';

const starterJson = {
  'type': `doc`,
  'content': [
    {
      'type': `paragraph`,
    },
  ],
};

interface ITextEditorProps {
  mergeTags: {
    text: string;
    value: string;
    new?: boolean;
  }[]
  onChange: (obj: {
    json: JSONContent;
    html: string;
    text: string;
  }) => void;
  mode?: `email` | `sms` | `signature`;
  value: string | typeof starterJson;
  htmlValue?: string;
  editorClassname?: string;
}

export const TextEditor = ({
  mergeTags,
  onChange,
  mode = `email`,
  value,
  htmlValue,
  editorClassname,
}: ITextEditorProps) => {
  const onUpdate: (props: EditorEvents[`update`]) => void = ({ editor }) => {
    const json = editor.getJSON();
    const html = editor.getHTML();
    const text = editor.getText();

    onChange({ json, html, text });
  };

  const linkExtension = Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: `text-indigo-600 hover:text-indigo-500 underline`,
    },
  });

  const textAlignExtension = TextAlign.configure({
    types: [`heading`, `paragraph`, `doc`],
    alignments: [`left`, `center`, `right`, `justify`],
  });

  const imageExtension = Image.configure({
    allowBase64: false,
    inline: false,
    HTMLAttributes: {
      class: `h-auto`,
    },
  });

  const dropCursorExtension = Dropcursor.configure({
    color: `#E5E7EB`,
    width: 1,
  });

  // const fileHandlerExtension = FileHandler.configure({
  //   allowedMimeTypes: [
  //     `image/jpeg`,
  //     `image/png`,
  //     `image/gif`,
  //   ],
  //   onPaste: (currentEditor, files, htmlContent) => {
  //     files.forEach(file => {
  //       if (htmlContent) {
  //         // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
  //         // you could extract the pasted file from this url string and upload it to a server for example
  //         console.log(htmlContent); // eslint-disable-line no-console

  //         return false;
  //       }

  //       const fileReader = new FileReader();

  //       fileReader.readAsDataURL(file);

  //       fileReader.onload = () => {
  //         currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
  //           type: `image`,
  //           attrs: {
  //             src: fileReader.result,
  //           },
  //         }).focus().run();
  //       };
  //     });
  //   },
  // });

  const extensions = [
    StarterKit,
    linkExtension,
    textAlignExtension,
    dropCursorExtension,
    mode === `signature` ? imageExtension : null,
    // mode === `signature` ? fileHandlerExtension : null,
  ].filter(f => f);

  const editor = useEditor({
    onUpdate,
    extensions,
    content: value || starterJson,
    editorProps: {
      transformPastedHTML: html => {
        console.log(html);

        return html;
      },
      attributes: {
        class: classNames(
          `editor-class bg-white p-4 border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-b-md`,
          mode === `sms` ? `min-h-[150px]` : `min-h-[350px]`,
          mode === `signature` && `min-h-[150px]`,
          editorClassname,
        ),
      },
    },
  });

  useEffect(() => {
    // If json is not present, like from a preset, then we can generate it now,
    // Otherwise use the starter
    if (htmlValue && !value && editor) {
      const json = generateJSON(htmlValue, extensions);

      editor.commands.setContent(json);
    }
  }, [value, htmlValue, editor]);

  useEffect(() => {
    // When upstream value changes, update the editor
    if (value && editor) {
      const { from, to } = editor.state.selection;

      editor.commands.setContent(value,
        false, {
          preserveWhitespace: `full`,
        });

      editor.commands.setTextSelection({ from, to });
    }
  }, [value, editor]);

  return (
    <div className={ `flex flex-col rounded-md` }>
      <MenuBar editor={ editor }
        mergeTags={ mergeTags }
        mode={ mode } />
      <EditorContent
        editor={ editor }
        className={ `` } />
    </div>
  );
};

