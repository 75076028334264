import { useMemo } from "react";

import { IOption, Select } from "../../../common/Atoms/Select";
import { useGetSelectedOrganisation } from "../../hooks/useGetSelectedOrganisation";

interface IAssigneeProps {
  current: string | null;
  onChange: (id: string) => void;
  loading?: boolean;
}

export function Assignee({
  current,
  onChange,
  loading,
}: IAssigneeProps) {
  const currentOrg = useGetSelectedOrganisation();

  const dropdownOpts: IOption[] = useMemo(() => {
    if (!currentOrg) return [];

    const currentOrgUsers = currentOrg.users.map(user => ({
      label: `${user.firstName} ${user.lastName} (${user.email})`,
      value: user.id,
      disabled: user.id === current,
    }));

    if (!currentOrgUsers.find(u => u.value === current)) {
      currentOrgUsers.unshift({
        label: `Removed User`,
        value: current,
        disabled: true,
      });
    }

    return currentOrgUsers;
  }, [currentOrg]);

  return (
    <Select
      options={ dropdownOpts }
      loading={ loading }
      selected={ current }
      onChange={ ({ value }) => onChange(value) }
      emptyText={ `Unassigned` }
      nullable
    />
  );
}
