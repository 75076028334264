import { useMemo } from "react";
import { PolicyCalculationType, PolicyGracePeriodType } from "shared";

import { InputTextAddon } from "../../../../../common/Atoms/InputTextAddon";
import { fieldIsValid } from "../../../../lib/helper";
import { Select } from "../../../../../common/Atoms/Select";

import { IFieldProps } from "./Fields/fieldProps";

export function GracePeriod({
  handleChange,
  policyFormData,
  invalids,
}: IFieldProps) {
  const options = useMemo(() => {
    return [
      {
        label: `Day${policyFormData.grace_period === 1 ? `` : `s`}`,
        value: PolicyGracePeriodType.DAYS,
        tooltip: `All calendar days`,
      },
      {
        label: `Weekday${policyFormData.grace_period === 1 ? `` : `s`}`,
        value: PolicyGracePeriodType.WEEKDAYS,
        tooltip: `Does not count weekends. Includes any local holidays.`,
      },
    ];
  }, [policyFormData.grace_period]);

  function handleDaysTypeChange({ value }) {
    return handleChange({
      value,
      name: `grace_period_type`,
    });
  }

  let description =``;

  if (policyFormData.calculation_type === PolicyCalculationType.PRORATA) {
    if (policyFormData.repeating && policyFormData.repeat_frequency) {
      description = `${description} The interest will always be calculated from the due date or the most recent late fee when repeating.`;
    }
    else {
      description = `${description} The interest will always be calculated from the due date.`;
    }
  }

  const dropdown = (
    <Select
      options={ options }
      selected={ policyFormData.grace_period_type }
      onChange={ handleDaysTypeChange }
      name={ `grace_period_type` }
      className={ `rounded-l-none` }
      nestedInTextField
    />
  );

  return (
    <>
      <InputTextAddon
        label={ `Grace Period` }
        description={ description }
        helpIcon={ `How many days after the due date to wait before applying the late fee? To give a margin of time to allow reconciliation` }
        value={ policyFormData.grace_period }
        onChange={ handleChange }
        name={ `grace_period` }
        invalid={ fieldIsValid(`grace_period`, invalids) }
        dataType={ `number` }
        addOnElement={ dropdown }
      />
    </>
  );
}
